// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/root.tsx");
}
// REMIX HMR END

import React, { useEffect, useState } from 'react';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useLoaderData, useLocation, useNavigate, useRouteError } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { cssBundleHref } from '@remix-run/css-bundle';
import { AlertContainer, showAlert } from '~common/components/alert';
import { getCollections } from '~storefront/providers/collections/provider';
import { activeChannel } from '~storefront/providers/channel/provider';
import { APP_META_DESCRIPTION, APP_META_TITLE } from '~storefront/constants';
import { CartTray } from '~storefront/modules/cart/components/CartTray';
import { getActiveCustomer } from '~storefront/providers/customer/provider';
import { list as getLocations } from '~storefront/providers/location/provider';
import { settings } from '~storefront/providers/settings/provider';
import Footer from '~storefront/components/footer';
import { useActiveOrder } from '~storefront/utils/useActiveOrder';
import { getChannelToken, setApiUrl } from '~storefront/graphqlWrapper';
import { ErrorMessage } from '~ui/Messages/ErrorMessage';
import { getAlertServer } from '~common/components/alert/server/index.server';
import { GoogleOneTap } from '~common/components/auth/components/GoogleOneTap';
import { Version } from '~common/components/Version';
import { getTimezone, setTimezone } from '~common/components/alert/server/default-session.server';
import { config as cartConfig } from './modules/cart/config';
import styles from './styles/app.css';
import { Header } from './components/header';
import { getActiveOrder } from './providers/order/provider';
import { channelTokenKey, sessionStorage } from './sessions.server';
export function ErrorBoundary() {
  _s();
  const error = useRouteError();
  console.log('Error boundary reached for root:' + JSON.stringify(error));

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return <html lang="en" id="app">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <title>MAOS Supported Application</title>
          <Links />
        </head>
        <body className="dark:bg-slate-700 dark:text-slate-400">
          <main className="h-screen flex items-center justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <ErrorMessage heading="We are unable to connect at the moment" message="
                        We could not connect due to a technical issue on our
                        end. Please try again shortly.
                        If the issue keeps happening, contact customer care
                        at 262-505-6555." />





              <p className="w-full text-center">Status: {error.status}</p>
              <p className="w-full text-center">{error.data.message}</p>
            </div>
          </main>
          <Scripts />
        </body>
      </html>;
  } else {
    if (error instanceof Error) {
      console.log(`General error: ${error.message}`);
    } else {
      console.log('Not a routing error:' + JSON.stringify(error));
    }
  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  const errorMessage = 'Unknown error';
  // if (isDefinitelyAnError(error)) {
  //   errorMessage = error.message;
  // }

  return <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>MAOS Supported Application</title>
        <Links />
      </head>
      <body className="dark:bg-slate-700 dark:text-slate-400">
        <main className="h-screen flex items-center justify-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <ErrorMessage heading="We are unable to connect at the moment" message="
                        We could not connect due to a technical issue on our
                        end. Please try again shortly.
                        If the issue keeps happening, contact customer care
                        at 262-505-6555." />





          </div>
        </main>
        <Scripts />
      </body>
    </html>;
}
_s(ErrorBoundary, "oAgjgbJzsRXlB89+MoVumxMQqKM=", false, function () {
  return [useRouteError];
});
_c = ErrorBoundary;
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: styles
}, {
  rel: 'stylesheet',
  href: cssBundleHref
}] : [])
// ...
];
export const meta = ({
  data
}) => {
  const metaTitle = data?.activeChannel.customFields?.metaTitle || data?.activeChannel.customFields?.storeTitle || APP_META_TITLE;
  const metaDescription = data?.activeChannel.customFields?.metaDescription || data?.activeChannel.customFields?.storeDescription || APP_META_DESCRIPTION;
  return [{
    title: metaTitle
  }, {
    name: 'description',
    content: metaDescription
  }];
};
const devMode = typeof process !== 'undefined' && process.env.NODE_ENV === 'development';
export async function loader({
  request,
  params,
  context
}) {
  try {
    const ENV = {
      DISABLE_SENTRY: process.env.DISABLE_SENTRY,
      SENTRY_DSN: process.env.SENTRY_DSN,
      VENDURE_API_URL: process.env.VENDURE_API_URL,
      VERSION: process.env.METRIX_VERSION ?? '0.0.0'
    };
    if (typeof context.VENDURE_API_URL === 'string') {
      // Set the API URL for Cloudflare Pages
      setApiUrl(context.VENDURE_API_URL);
    }
    const alertServer = getAlertServer();
    const {
      alert,
      headers
    } = await alertServer.getAlert(request);
    const channel = await activeChannel({
      request
    });
    const session = await getTimezone(request);
    if (session.get('timezone') !== channel?.customFields?.timezone) {
      const ck = await setTimezone(request, channel?.customFields?.timezone ?? '');
      headers.append('Set-Cookie', ck);
    }
    const channelToken = await getChannelToken(request, undefined, true);
    const rmxSession = await sessionStorage.getSession(request?.headers.get('Cookie'));
    if (rmxSession.get(channelTokenKey) !== channelToken) {
      rmxSession.set(channelTokenKey, channelToken);
      const ck = await sessionStorage.commitSession(rmxSession);
      headers.append('Set-Cookie', ck);
    }
    const frontendSettings = await settings({
      request
    });
    const collectionData = await getCollections(undefined, {
      request
    });
    const collections = collectionData.collections.items;
    const topLevelCollections = collections.filter(collection => collection.parent?.name === '__root_collection__');
    const locations = await getLocations(undefined, {
      request
    });
    const activeCustomer = await getActiveCustomer({
      request
    });
    let activeOrder;
    if (activeCustomer) {
      activeOrder = await getActiveOrder({
        request
      });
    }
    const loaderData = {
      settings: frontendSettings.settings,
      activeCustomer,
      activeOrder,
      activeChannel: channel,
      collections: topLevelCollections,
      locations: locations.rsv_locations?.items ?? [],
      alert: alert,
      ENV
    };
    return json(loaderData, {
      headers /*: activeCustomer._headers*/
    });
  } catch (err) {
    console.log('Error root loader', err);
    throw err;
  }
}
export default function App() {
  _s2();
  const [open, setOpen] = useState(false);
  const loaderData = useLoaderData();
  const {
    alert,
    collections,
    activeChannel,
    activeOrder: initialOrder,
    activeCustomer,
    locations,
    ENV
  } = loaderData;
  const {
    activeOrderFetcher,
    activeOrder,
    adjustOrderLine,
    removeItem,
    applyCoupon,
    removeCoupon,
    refresh,
    loading: activeOrderLoading
  } = useActiveOrder(initialOrder);
  const location = useLocation();
  const navigate = useNavigate();
  const handleMessage = React.useCallback(message => {
    const {
      data
    } = message;
    if (data) {
      const {
        appid,
        title,
        message
      } = JSON.parse(data);
      showAlert({
        type: 'success',
        // @ts-ignore ok-is
        title,
        message
      });
    }
  }, []);
  React.useEffect(() => {
    if (alert) {
      // @ts-ignore ok-is
      showAlert(alert);
    }
  }, [alert]);
  useEffect(() => {
    // When the loader has run, this implies we should refresh the contents
    // of the activeOrder as the user may have signed in or out.
    refresh();
  }, [loaderData]);
  const handleOpenCart = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleCloseCart = React.useCallback(() => {
    if (location.pathname === cartConfig.path) {
      navigate('/');
    }
    setOpen(false);
  }, [location.pathname]);
  const metaTitle = activeChannel.customFields?.metaTitle;
  const metaDescription = activeChannel.customFields?.metaDescription;
  const favIcon = activeChannel.customFields?.favIcon?.preview;
  const [isSignedIn, setIsSignedIn] = useState(false);
  const editable = !location.pathname.startsWith('/checkout');
  return <html lang="en" id="app">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href={favIcon} type="image/png"></link>
        {/* <title>{metaTitle}</title>
          {metaDescription && (
           <>
             <meta name="description" content={metaDescription} />
           </>
          )} */}
        <Meta />
        <Links />
      </head>
      <body className="dark:bg-slate-700">
        <Header onLogOutClick={() => setIsSignedIn(!isSignedIn)} onCartIconClick={() => setOpen(!open)} cartQuantity={activeOrder?.totalQuantity ?? 0} />

        <AlertContainer />
        <main className="bg-gray-100 dark:bg-slate-800 dark:text-slate-400 pb-24 h-full">
          <Outlet context={{
          settings,
          activeOrderFetcher,
          activeOrder,
          activeOrderLoading,
          adjustOrderLine,
          removeItem,
          applyCoupon,
          removeCoupon,
          activeChannel,
          openCart: handleOpenCart
        }} />

        </main>
        <CartTray open={open} onClose={handleCloseCart} activeOrder={activeOrder} onAdjustOrderLine={adjustOrderLine} onRemoveItem={removeItem} onRemoveCouponCode={removeCoupon} editable={editable} />

        <Footer collections={collections} activeChannel={activeChannel} locations={locations} />


        {activeChannel.customFields?.googleClientId && !activeCustomer.activeCustomer ? <GoogleOneTap key={activeChannel.customFields?.googleClientId} googleClientId={activeChannel.customFields?.googleClientId} action="/sign-in" /> : null}
        <ScrollRestoration />
        <script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(ENV)}`
      }} />

        <Scripts />
        {devMode && <LiveReload />}
        <Version hide className="bg-white dark:bg-slate-900 text-gray-400 dark:text-slate-500" version={ENV.VERSION} />

      </body>
    </html>;
}
_s2(App, "kY16/vB7eKt5xGKurYThAfV6Kio=", false, function () {
  return [useLoaderData, useActiveOrder, useLocation, useNavigate];
});
_c2 = App;
function isDefinitelyAnError(error) {
  throw new Error('Function not implemented.');
}
var _c, _c2;
$RefreshReg$(_c, "ErrorBoundary");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;